import React from 'react'
import Button from '../../components/UI/Button'
import './Footer.scss'

const Footer = ({ handleClick }) => {
    return (
        <div className="Footer">
            <div className="Footer__btn">
                <Button
                    text={'Start Chat for Free'}
                    classes={['btn', 'black']}
                    handleClick={handleClick}
                />
            </div>
            <div className="Footer__logo">
                <img src={'/images/logo_olive.png'} alt={`footer_logo`}></img>
            </div>
        </div>
    )
}

export default Footer
