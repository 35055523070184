import React, { Fragment } from 'react'
import './MainBenefitsStack.scss'

const MainBenefitsStack = ({ textStack }) => {
    return (
        <Fragment>
            {textStack.map(({ header, secondary, icon, circle, description }, index) => {
                return (
                    <div className="benefits__stack-item" key={`${description}__${index}`}>
                        <div className="benefits__stack-item__icon">
                            <img src={circle} alt={`circle_${description}`} />
                            <img src={icon} alt={description} />
                        </div>
                        <div className="benefits__stack-item__text">
                            <h1>{header}</h1>
                            <span>{secondary}</span>
                        </div>
                    </div>
                )
            })}
        </Fragment>
    )
}

export default MainBenefitsStack
