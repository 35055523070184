import React from 'react'
import './Button.scss'

const Button = ({ text, classes, handleClick }) => {
    const cls = classes.join(' ')
    return (
        <button className={cls} onClick={handleClick}>
            {text}
        </button>
    )
}

export default Button

// https://olive.onelink.me/er8r/2a52cf6b
