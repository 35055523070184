import React from 'react'
import {
    MainBannerPhotos,
    MainBannerText,
    MainBenefitsStack,
    MainUsers,
} from '../../components/MainBanner'
import Button from '../../components/UI/Button'
import './Main.scss'

const textStack = [
    {
        header: 'LIVE Video Chat',
        secondary: 'Chat with strangers and have fun',
        icon: '/images/video.png',
        circle: '/images/Circle_benefit.png',
        description: 'video',
    },
    {
        header: 'No fake profiles',
        secondary: 'Only REAL PROFILES from all over the world',
        icon: '/images/3_users.png',
        circle: '/images/Circle_benefit.png',
        description: 'users',
    },
    {
        header: '24/7 video chatting',
        secondary: 'No swiping around or waiting days for a reply',
        icon: '/images/chat.png',
        circle: '/images/Circle_benefit.png',
        description: 'chat',
    },
]

const Main = ({ handleClick }) => {
    return (
        <main className="Main">
            <section className="Main__banner">
                <div className="Main__banner__text">
                    <MainBannerText />
                </div>
                <div className="Main__banner__image-stack">
                    <MainBannerPhotos />
                </div>
            </section>
            <section className="Main__benefits">
                <div className="Main__benefits__btn">
                    <Button
                        text={'Try for free'}
                        classes={['btn', 'yellow']}
                        handleClick={handleClick}
                    />
                </div>
                <div className="Main__benefits__line">
                    <img src={'/images/yellow_line.png'} alt={'yellow_line'} />
                </div>
                <div className="Main__benefits__stack">
                    <MainBenefitsStack textStack={textStack} />
                </div>
            </section>
            <section className="Main__users">
                <MainUsers />
            </section>
        </main>
    )
}

export default Main
