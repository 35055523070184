import React, { Component } from 'react'
import { install } from 'ga-gtag';
import OneLinkUrlGenerator from './AppsFlyerLinkGenerator';
import Footer from './containers/Footer'
import Header from './containers/Header'
import Main from './containers/Main'
import BannerWrapper from './layout/BannerWrapper'

class App extends Component {
    state = {
        urlAppLink: ''
    }
    handleBtnClick = () => {
        const timeOut = setTimeout(() => {
            window.open(this.state.urlAppLink, '_blank')
            clearTimeout(timeOut)
        }, 500)
    }

    componentDidMount() {
        (function(){
            window.AF = Object.assign((window.AF || {}),{OneLinkUrlGenerator: OneLinkUrlGenerator});
        })();

        const linkGenerator =  new window.AF.OneLinkUrlGenerator({
            oneLinkURL: "https://olivevideochat.onelink.me/F0qB",
            pidKeysList: ['af_pid', 'pid', 'utm_source'],
            campaignKeysList: ['af_c', 'c', "campaign.name", 'utm_campaign'],
            gclIdParam: "af_sub2",
            campaignStaticValue: 'defaults',
            pidStaticValue: 'defaults',
        });
        linkGenerator.setChannel("af_channel", "defaults");
        linkGenerator.setAdset("af_adset", "defaults");
        linkGenerator.setAd("af_ad", "defaults");
        linkGenerator.setCustomParameter("af_c_id", "af_c_id","defaults");
        linkGenerator.setCustomParameter("af_adset_id", "af_adset_id", "defaults");
        linkGenerator.setCustomParameter("af_ad_id", "af_ad_id","defaults");

        let generatedString = linkGenerator.generateUrl();
        
        if (generatedString) {
            generatedString += `&${getFBCookieString()}`
        }
    
        function getFBCookieString() {
            const cookies = document.cookie;

            return cookies
                .split('; ')
                .map(cookie => {
                    if (cookie.startsWith('_fb')) {
                        return cookie.substring(1);
                    }
                    return null;
                })
                .filter(Boolean)
                .join('&');
        }

        console.log(generatedString);
        this.setState({ ...this.state, urlAppLink: generatedString })
        install('G-S1838NSZ7G')
    }

    render() {
        return (
            <div className="App">
                <BannerWrapper>
                    <Header handleClick={this.handleBtnClick} />
                    <Main handleClick={this.handleBtnClick} />
                    <Footer handleClick={this.handleBtnClick} />
                </BannerWrapper>
            </div>
        )
    }
}

export default App
