import React, { Fragment } from 'react'
import './MainBannerPhotos.scss'

const MainBannerPhotos = () => {
    return (
        <Fragment>
            <div className="image-stack__item">
                <img src={'/images/blur_1.png'} alt="blur" />
            </div>
            <div className="image-stack__item">
                <img src={'/images/iPhone_X.png'} alt="iphoneX" />
            </div>
            <div className="image-stack__item">
                <img src={'/images/man_photo.png'} alt="man" />
            </div>
            <div className="image-stack__item">
                <img src={'/images/icons_group.png'} alt="icons" />
            </div>
            <div className="image-stack__item">
                <img src={'/images/Platter.png'} alt="platter" />
                <div>
                    <img src={'/images/Platter_circle-dark.png'} alt="platter_dark" />
                    <img src={'/images/Platter_circle-yellow.png'} alt="platter_yellow" />
                    <img src={'/images/ava.png'} alt="ava" />
                    <div>
                        <span>Ketrin</span>
                        <span>USA</span>
                        <img src={'/images/heart_1.png'} alt="heart" />
                        <span>128</span>
                        <img src={'/images/Platter_secondary.png'} alt="platter" />
                        <span>26</span>
                        <img src={'/images/female_gender_small.png'} alt={'female_gender'}></img>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainBannerPhotos
