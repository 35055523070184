import React, { Fragment } from 'react'
import './MainUsers.scss'

const MainUsers = () => {
    return (
        <Fragment>
            <div className="Users__header">
                <h1>Online Users</h1>
                <div></div>
            </div>
            <div className="Users__quantity">
                <div className="Users__quantity__men">
                    <span>2 986</span>
                </div>
                <div className="Users__quantity__women">
                    <span>12 578</span>
                </div>
            </div>
            <div className="Users__graphs">
                <div className="Users__graphs__men"></div>
                <div className="Users__graphs__women"></div>
            </div>
            <div className="Users__genders">
                <div className="Users__genders__men">
                    <span>Men</span>
                </div>
                <div className="Users__genders__women">
                    <span>Women</span>
                    <div className="Users__genders__women-icons">
                        <img src={'/images/profile_1.png'} alt={`profile_1`}></img>
                        <img src={'/images/profile_2.png'} alt={`profile_1`}></img>
                        <img src={'/images/profile_3.png'} alt={`profile_1`}></img>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainUsers
