import React, { Fragment } from 'react'
import './MainBannerText.scss'

const MainBannerText = () => {
    return (
        <Fragment>
            <div className="text-group">
                <h1>EACH CALL IS SPECIAL</h1>
                <span>join live video chat</span>
                <img src={'/images/yellow_line.png'} alt={'yellow_line'} />
            </div>
        </Fragment>
    )
}

export default MainBannerText
