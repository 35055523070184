import React from 'react'
import { Logo, LogoText } from '../../components/HeaderLogo'
import Button from '../../components/UI/Button'
import './Header.scss'

const Header = ({ handleClick }) => {
    return (
        <header className="Header">
            <div className="Header__container">
                <div className="Header__container_logo">
                    <Logo />
                    <LogoText />
                </div>
                <Button
                    text={'Install now'}
                    classes={['btn', 'btn-logo']}
                    handleClick={handleClick}
                />
            </div>
        </header>
    )
}

export default Header
