import React from 'react'

export default function LogoText() {
    return (
        <div className="logo-text-group">
            <span className="logo-text">Olive</span>
            <span className="logo-text">Video Chat</span>
        </div>
    )
}
